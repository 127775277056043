import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import SectionTitle from "../common/SectionTitle";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import pageBg from "../../assets/images/projects_bg.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { ROUTES } from "../../common/router/constants";
import { useWPPageContext } from "../../common/context/WPPageContext";
import ProjectDetails from "./ProjectDetails";
import { device } from "../../utilities/breakpoints";
import { IPage } from "../../common/types/pages.types";

const StyledSection = styled("div")`
  background: #262626;
  width: 100%;
  position: relative;
  z-index: 2;
  overflow: visible;
  padding-top: 8rem;
  padding-bottom: 2rem;
  min-height: 100vh;
  background-image: url(${pageBg});
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;

  .content {
    padding: 0;
    position: relative;
    z-index: 4;
    width: min(1400px, 100%);
    margin-left: auto;
    margin-right: auto;

    p {
      line-height: 1.5;
      margin-bottom: 1rem;
    }
  }

  .gridItemWrapper {
    padding: 2rem;
  }

  .projectList {
    padding-top: 3rem;
    position: sticky;
    top: 50px;
    bottom: 100px;
    @media ${device.mobileS} {
      padding: 3rem 2rem 2rem 2rem;
    }
    @media ${device.laptop} {
      padding: 3rem 0;
    }
    @media ${device.laptopL} {
      padding: 3rem 0;
    }
    ul {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: block;
        margin: 0;
        &:not(:last-of-type) {
          margin-bottom: 2rem;
        }

        a {
          color: rgba(255, 255, 255, 0.4);
          text-decoration: none;
          transition: all 0.3s ease-out;
          display: block;

          > div {
            font-size: 1.35rem;
            font-weight: 600;
            line-height: 1.5;
            margin: 0rem;
          }
          > span {
            font-size: 1rem;
            font-weight: 200;
            line-height: 1.5;
            margin: 0rem;
          }

          &.current,
          &:hover {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }
`;

interface IProjectsContent {
  data: IPage<any>;
}

const ProjectsContent: FC<IProjectsContent> = ({ data }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();

  const { isLoading, subLoading, setCurrentRoute } = useWPPageContext();
  const [projects, setProjects] = useState<any>();
  const [selected, setSelected] = useState<any>();

  const isCurrent = (selectedId: number) => {
    return selected?.ID === selectedId;
  };

  const handleProjectSelect = (e: React.MouseEvent<HTMLElement>) => {
    if (subLoading) e.preventDefault();
  };

  useEffect(() => {
    if (pathname.indexOf(ROUTES.PROJECTS) > -1 && data && !isLoading) {
      setProjects(data?.acf?.page_items);
    }
  }, [pathname, data, isLoading]);

  useEffect(() => {
    if (pathname.indexOf(ROUTES.PROJECTS) > -1) {
      if (id && projects) {
        setSelected(projects?.filter((item: any) => item.ID === +id)[0]);
      }
      if (!id && projects) {
        const defaultId = projects[0]?.ID;
        setCurrentRoute(`${ROUTES.PROJECTS}/${defaultId}`);
        navigate(`${ROUTES.PROJECTS}/${defaultId}`);
      }
    }
  }, [pathname, navigate, projects, id]);

  return (
    <StyledSection>
      <div className="content">
        <SectionTitle title="Projects" />
        <Grid container spacing={0}>
          <Grid xs={12} md={3}>
            <div className="projectList">
              <ul>
                {projects &&
                  projects?.map((item: any, i: number) => (
                    <li key={i}>
                      <Link
                        to={`${ROUTES.PROJECTS}/${item.ID}`}
                        onClick={handleProjectSelect}
                        className={classNames({
                          current: isCurrent(item.ID),
                        })}
                      >
                        <div>{item.post_title}</div>
                        <span>{item.post_excerpt}</span>
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </Grid>
          <Grid xs={12} md={9}>
            <div className="gridItemWrapper">{id && <ProjectDetails />}</div>
          </Grid>
        </Grid>
      </div>
    </StyledSection>
  );
};

export default ProjectsContent;
