import React from "react";
import BlocksBg from "../../assets/images/BlockBg";
import styled from "styled-components";
import SectionTitle from "../common/SectionTitle";
import { Grid, GridItem } from "../common/Grid";
import Button from "../common/Button";
import { ROUTES } from "../../common/router/constants";
import { useWPPageContext } from "../../common/context/WPPageContext";
import parse from "html-react-parser";
import { formatWPRender } from "../../common/utils/format";

const StyledSection = styled("div")`
  background: #262626;
  width: 100%;
  position: relative;
  z-index: 2;
  overflow: visible;
  padding-bottom: 2rem;

  .content {
    padding: 2rem;
    position: relative;
    z-index: 4;
    width: min(1400px, 100%);
    margin-left: auto;
    margin-right: auto;
  }

  .counterBox {
    padding: 0 2rem;
    .number {
      font-size: 6rem;
      font-weight: 600;
      line-height: 1.2;
      margin-bottom: 1rem;
      text-align: center;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      > span {
        display: inline-block;
      }
      > span:nth-child(2) {
        color: #fff500;
        font-size: 4rem;
        margin-left: 10px;
      }
    }
    .label {
      font-size: 1rem;
      line-height: 1.2;
      margin-bottom: 2rem;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
  .learnMore {
    padding-top: 6rem;
    text-align: center;
  }
`;

const HomeAchievements = () => {
  const { page } = useWPPageContext();

  const getDigitSuffix = (val: string) => {
    let suffix = "";
    if (val === "plus") suffix = "+";
    if (val === "percentage") suffix = "%";
    return suffix;
  };
  return (
    <StyledSection>
      <div className="content">
        <SectionTitle title="Achievement" />
        <Grid>
          <GridItem width="33.33%">
            <div className="counterBox">
              <div className="number">
                <span>{page?.acf.achievement_section_1_value}</span>
                {page?.acf.achievement_section_1_type !== "" && (
                  <span>
                    {getDigitSuffix(page?.acf.achievement_section_1_type)}
                  </span>
                )}
              </div>
              <div className="label">
                {page?.acf.achievement_section_1_label}
              </div>
              <div className="excerpt wpContentBody">
                {parse(
                  formatWPRender(page?.acf.achievement_section_1_description),
                )}
              </div>
            </div>
          </GridItem>
          <GridItem width="33.33%">
            <div className="counterBox">
              <div className="number">
                <span>{page?.acf.achievement_section_2_value}</span>
                {page?.acf.achievement_section_2_type !== "" && (
                  <span>
                    {getDigitSuffix(page?.acf.achievement_section_2_type)}
                  </span>
                )}
              </div>
              <div className="label">
                {page?.acf.achievement_section_2_label}
              </div>
              <div className="excerpt wpContentBody">
                {parse(
                  formatWPRender(page?.acf.achievement_section_2_description),
                )}
              </div>
            </div>
          </GridItem>
          <GridItem width="33.33%">
            <div className="counterBox">
              <div className="number">
                <span>{page?.acf.achievement_section_3_value}</span>
                {page?.acf.achievement_section_3_type !== "" && (
                  <span>
                    {getDigitSuffix(page?.acf.achievement_section_3_type)}
                  </span>
                )}
              </div>
              <div className="label">
                {page?.acf.achievement_section_3_label}
              </div>
              <div className="excerpt wpContentBody">
                {parse(
                  formatWPRender(page?.acf.achievement_section_3_description),
                )}
              </div>
            </div>
          </GridItem>
        </Grid>
        <div className="learnMore">
          <Button to={ROUTES.PROJECTS} label="Learn More" />
        </div>
      </div>
      <BlocksBg />
    </StyledSection>
  );
};

export default HomeAchievements;
