import React, { FC, useEffect, useState } from "react";
import parse from "html-react-parser";
import { formatWPRender } from "../../common/utils/format";
import Subloader from "../common/Subloader";
import ProjectGallery from "./ProjectGallery";
import styled from "styled-components";
import { API_ROUTES } from "../../common/api/apiRoutes";
import { useWPPageApi } from "../../common/api/useWPPageApi";
import { useParams } from "react-router-dom";

const StyleContainer = styled("div")`
  .context {
    padding: 0;
    margin-bottom: 2rem;
  }
`;

const ProjectDetails: FC = () => {
  const { id } = useParams();
  const [gallery, setGallery] = useState<any>();
  const endpoint = `${API_ROUTES.PROJECT_DETAILS}/${id}`;
  const { subLoading, data } = useWPPageApi(endpoint, false);

  useEffect(() => {
    if (data) {
      setGallery([
        {
          url: data?.acf?.project_image_1?.url,
          thumbnail: data?.acf?.project_image_1?.sizes?.thumbnail,
        },
        {
          url: data?.acf?.project_image_2?.url,
          thumbnail: data?.acf?.project_image_2?.sizes?.thumbnail,
        },
        {
          url: data?.acf?.project_image_3?.url,
          thumbnail: data?.acf?.project_image_3?.sizes?.thumbnail,
        },
        {
          url: data?.acf?.project_image_4?.url,
          thumbnail: data?.acf?.project_image_4?.sizes?.thumbnail,
        },
        {
          url: data?.acf?.project_image_5?.url,
          thumbnail: data?.acf?.project_image_5?.sizes?.thumbnail,
        },
        {
          url: data?.acf?.project_image_6?.url,
          thumbnail: data?.acf?.project_image_6?.sizes?.thumbnail,
        },
      ]);
    }
  }, [data]);
  return (
    <StyleContainer className="content">
      {subLoading ? (
        <Subloader />
      ) : (
        <div className="wpContentBody">
          <h1>{data?.title?.rendered}</h1>
          <div className="content">
            {parse(formatWPRender(data?.content?.rendered))}
          </div>
          <ProjectGallery gallery={gallery} />
        </div>
      )}
    </StyleContainer>
  );
};

export default ProjectDetails;
