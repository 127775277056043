import React from "react";

function IconArrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="25"
      fill="none"
      viewBox="0 0 22 25"
    >
      <path
        fill="#FFF500"
        fillRule="evenodd"
        d="M10.735.497L21.505 11.3c.66.663.66 1.737 0 2.4l-10.77 10.803a1.689 1.689 0 01-2.394 0 1.701 1.701 0 010-2.4l7.881-7.906H0v-3.394h16.222l-7.88-7.905a1.701 1.701 0 010-2.4 1.689 1.689 0 012.393 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default IconArrowRight;
