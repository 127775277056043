import React, { FC } from "react";
import styled from "styled-components";
import RunningStripes from "../../assets/images/RunningStripes";
import { device } from "../../utilities/breakpoints";

const StyledContainer = styled("div")`
  .title {
    width: 100%;
    position: relative;
    padding-right: 60px;
    margin-bottom: 4rem;
    @media ${device.mobileS} {
      padding-right: 0px;
    }
    @media ${device.laptop} {
      padding-right: 60px;
    }
    h1 {
      text-transform: uppercase;
      font-weight: 200;
      font-size: 2rem;
      line-height: 1.25;
      display: block;
      width: 100%;
      color: #fff500;
      border-bottom: 1px solid #fff500;

      @media ${device.mobileS} {
        padding-right: 0px;
      }
      @media ${device.laptop} {
        padding-right: 210px;
      }
    }
    .runningStripes {
      position: absolute;
      bottom: 0.5rem;
      right: 63px;
      @media ${device.mobileS} {
        right: 0;
        bottom: -2rem;
      }
      @media ${device.laptop} {
        right: 63px;
        bottom: 0.5rem;
      }
    }
    .extras {
      @media ${device.mobileS} {
        display: none;
      }
      @media ${device.laptop} {
        display: block;
      }
      &:before {
        content: "";
        display: block;
        width: 60px;
        height: 1px;
        background-color: #fff500;
        position: absolute;
        right: 0;
        top: calc(100% - 1px);
        transform-origin: top left;
        transform: rotate(45deg);
      }
      > div {
        position: absolute;
        top: calc(100% + 42px);
        right: 2px;
        width: 16px;
        height: 16px;
        animation: SectionTitleExtrasBlink infinite linear 0.1s;
        &:before,
        &:after {
          content: "";
          display: block;
          width: 16px;
          height: 1px;
          background-color: rgba(255, 255, 255, 0.5);
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -0.5px;
          margin-left: -8px;
        }
        &:after {
          transform: rotate(90deg);
        }
      }
    }
  }
  @keyframes SectionTitleExtrasBlink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

interface ISectionTitle {
  title: string;
}

const SectionTitle: FC<ISectionTitle> = (props) => {
  const { title } = props;
  return (
    <StyledContainer>
      <div className="title">
        <h1>
          <span>{title}</span>
        </h1>
        <RunningStripes />
        <div className="extras">
          <div />
        </div>
      </div>
    </StyledContainer>
  );
};

export default SectionTitle;
