import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { ROUTES } from "./constants";
import Home from "../../views/Home";
import About from "../../views/About";
import Services from "../../views/Services";
import Projects from "../../views/Projects";

const router = createBrowserRouter([
  {
    path: ROUTES.HOME,
    element: <Home />,
  },
  {
    path: ROUTES.ABOUT,
    element: <About />,
  },
  {
    path: ROUTES.SERVICES,
    element: <Services />,
  },
  {
    path: `${ROUTES.PROJECTS}`,
    element: <Projects />,
  },
  {
    path: `${ROUTES.PROJECTS}/:id`,
    element: <Projects />,
  },
  {
    path: ROUTES.CONTACT,
    element: <Home />,
  },
]);

export { router };
