import React, { useEffect } from "react";
import Layout from "../components/common/Layout";
import Hero from "../components/Home/Hero";
import HomeServices from "../components/Home/HomeServices";
import HomeAchievements from "../components/Home/HomeAchievements";
import HomeContact from "../components/Home/HomeContact";
import { API_ROUTES } from "../common/api/apiRoutes";
import { useWPPageApi } from "../common/api/useWPPageApi";
import { useWPPageContext } from "../common/context/WPPageContext";
import Preloader from "../components/common/Preloader";

const Home = () => {
  const { data } = useWPPageApi(API_ROUTES.HOME_PAGE);
  const { isLoading } = useWPPageContext();
  return (
    <Layout isLoading={isLoading}>
      <Hero />
      <HomeServices />
      <HomeAchievements />
      <HomeContact />
    </Layout>
  );
};

export default Home;
