import classNames from "classnames";
import React, { FC, ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";

const StyledWrapper = styled("div")`
  display: inline-block;
  background-color: #000000;
  color: #fff500;
  width: auto;

  a {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff500;
    text-decoration: none;
    padding: 1.5rem 3rem;
    transition: all 0.15s ease-out;
  }

  .icon {
    height: 100%;
    width: auto;
    margin-left: 1rem;
    svg {
      path {
        /* fill: #fff500; */
        /* stroke: #fff500; */
        transition: all 0.15s ease-out;
      }
    }
  }

  &:hover {
    a {
      color: #ffffff;
    }

    .icon {
      svg {
        path: {
          fill: #ffffff;
          stroke: #ffffff;
        }
      }
    }
  }
`;

interface ISolidButton extends LinkProps {
  icon?: ReactNode;
  label?: string;
  onClick?: () => void;
}

const SolidButton: FC<ISolidButton> = (props) => {
  const { icon, to, className, label, onClick } = props;
  return (
    <StyledWrapper
      className={classNames({
        [`${className}`]: className,
      })}
    >
      <Link to={to} className="link" onClick={onClick}>
        <span>{label}</span>
        {icon && <span className="icon">{icon}</span>}
      </Link>
    </StyledWrapper>
  );
};

export default SolidButton;
