import classNames from "classnames";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  position: relative;
  display: inline-block;
  width: auto;
  text-decoration: none;
  outline: none;
  padding: 2rem 3rem;
  color: #fff500;
  line-height: 0;
  transition: all ease-out 0.15s;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #fff500;
    border-radius: 4rem;
    transition: all ease-out 0.15s;
    transform-origin: center center;
    box-shadow: 0 0 1rem 0 rgba(255, 255, 255, 0);
  }
  span {
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
  }

  &:hover {
    color: #ffffff;
    &:before {
      transform: scale(0.9);
      border-color: #ffffff;
      box-shadow: 0 0 1rem 0 rgba(255, 255, 255, 0.5);
    }
  }
`;

interface IButton {
  to: string;
  label: string;
  className?: string;
}

const Button: FC<IButton> = (props) => {
  const { to, label, className } = props;
  return (
    <StyledLink
      to={to}
      className={classNames("more", {
        [`${className}`]: !!className,
      })}
    >
      <span>{label}</span>
    </StyledLink>
  );
};

export default Button;
