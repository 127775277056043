import styled from "styled-components";
import { device } from "../../utilities/breakpoints";

const StyledHero = styled("div")`
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 2;
  background-color: #1e1e1e;
  > img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom center;
  }
  .taglines {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1000px;
    z-index: 2;
    .mobile {
      @media ${device.mobileS} {
        display: block;
        text-align: center;
        .runningStripes {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 1rem;
        }
      }
      @media ${device.laptop} {
        display: none;
      }
    }
    .desktop {
      display: none;
      @media ${device.mobileS} {
        display: none;
      }
      @media ${device.laptop} {
        display: block;
      }
    }
    h1,
    h2 {
      text-transform: uppercase;
      line-height: 1.2;
    }
    h2 {
      color: #ffffff;
      font-weight: 600;
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    h1 {
      color: #fff500;
      font-weight: 200;
      font-size: 2.5rem;
    }
  }
  .start {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 60px;
    text-decoration: none;
    cursor: pointer;
    > p {
      display: block;
      margin: 0 0 0.5rem 0;
      padding: 0;
      color: #fff500;
      text-align: center;
      font-size: 0.7rem;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.4rem;
      transition: all 0.1s ease-out;
    }
    > div {
      width: 59px;
      height: 59px;
      border-radius: 60px;
      border: 1px solid #fff500;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.1s ease-out;
      transform-origin: center center;
    }

    &:hover {
      > p {
        color: #ffffff;
      }
      > div {
        transform: scale(0.9);
        border-color: #ffffff;
        box-shadow: 0 0 1rem 0 rgba(255, 255, 255, 0.5);
      }
      svg > path {
        fill: #ffffff;
      }
    }
  }
`;

export default StyledHero;
