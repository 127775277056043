import React from "react";

const ArrowUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#FFF500"
        fillRule="evenodd"
        d="M.353 8.112L8.033.357a1.198 1.198 0 011.706 0l7.679 7.755a1.227 1.227 0 010 1.723 1.198 1.198 0 01-1.707 0l-5.619-5.674v12.9H7.68V4.16l-5.62 5.674a1.198 1.198 0 01-1.706 0 1.227 1.227 0 010-1.723z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default ArrowUp;
