import React, { useEffect } from "react";
import Layout from "../components/common/Layout";
import AboutContent from "../components/About/AboutContent";
import { API_ROUTES } from "../common/api/apiRoutes";
import { useWPPageApi } from "../common/api/useWPPageApi";
import { useWPPageContext } from "../common/context/WPPageContext";

const About = () => {
  const { data } = useWPPageApi(API_ROUTES.ABOUT_PAGE);
  const { isLoading } = useWPPageContext();
  return (
    <Layout isLoading={isLoading}>
      <AboutContent />
    </Layout>
  );
};

export default About;
