import React from "react";
import styled from "styled-components";
import vector1 from "../../assets/images/Vector1.png";
import vector2 from "../../assets/images/Vector2.png";
import { device } from "../../utilities/breakpoints";

const StyledWrapper = styled("div")`
  width: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  pointer-events: none;
  z-index: 1;
  @media ${device.mobileS} {
    margin-top: -5vh;
  }
  @media ${device.laptop} {
    margin-top: -20vh;
  }
  @media ${device.laptopL} {
    margin-top: -30vh;
  }
  .vectors {
    width: 100%;
    min-height: 1000px;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: auto;
      transform-origin: center center;
      &:nth-child(2) {
        left: 100%;
      }
    }
    .group {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      animation: slide infinite 100s linear;
      z-index: 1;
      &:nth-child(2) {
        animation: slide infinite 300s linear;
        z-index: 0;
      }
    }
  }

  @keyframes slide {
    0% {
      transform: translateX(0vw);)
    }
    100% {
      transform: translateX(-100vw);)
    }
  }
`;

const BlocksBg = () => {
  return (
    <StyledWrapper>
      <div className="vectors">
        <div className="group">
          <img src={vector1} alt="vector1" />
          <img src={vector1} alt="vector1" />
        </div>
        <div className="group">
          <img src={vector2} alt="vector2" />
          <img src={vector2} alt="vector2" />
        </div>
      </div>
    </StyledWrapper>
  );
};

export default BlocksBg;
