import React from "react";
import classNames from "classnames";
import HeroTagline from "./HeroTagline";
import ArrowDown from "../../assets/images/ArrowDown";
import StyledHero from "./Hero.styles";
import RunningStripes from "../../assets/images/RunningStripes";
import { useWPPageContext } from "../../common/context/WPPageContext";
import { Link } from "react-router-dom";

const Hero = () => {
  const { page } = useWPPageContext();

  const handleStartSlide = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    window.scrollBy({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  return (
    <StyledHero>
      <div className={classNames("taglines")}>
        <div className={classNames("mobile")}>
          <h2>{page?.acf?.hero_title}</h2>
          <RunningStripes />
          <h1>{page?.acf?.hero_subtitle}</h1>
        </div>
        <div className={classNames("desktop")}>
          <HeroTagline
            title={page?.acf?.hero_title}
            subtitle={page?.acf?.hero_subtitle}
          />
        </div>
      </div>
      <Link to="#" onClick={handleStartSlide} className={classNames("start")}>
        <p>Start</p>
        <div>
          <ArrowDown />
        </div>
      </Link>
      <img src={page?.acf?.hero_image?.url} alt="hero" />
    </StyledHero>
  );
};

export default Hero;
