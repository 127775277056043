import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

interface IDialogContext {
  children?: ReactNode;
}

type DialogContextType = {
  open: boolean;
  setOpen: (e: boolean) => void;
  toggleOpen: () => void;
};

const DialogContext = createContext<DialogContextType>({
  open: false,
  setOpen: (e: boolean) => e,
  toggleOpen: () => {},
});

export const DialogProvider: FC<IDialogContext> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [open]);

  return (
    <DialogContext.Provider value={{ open, toggleOpen, setOpen }}>
      {children}
    </DialogContext.Provider>
  );
};

export const useDialogContext = () => {
  return useContext(DialogContext);
};
