import React from "react";
import styled from "styled-components";
import RunningStripes from "../../assets/images/RunningStripes";

const StyleContainer = styled("div")`
  .text {
    animation: subloaderTextBlink 0.1s linear infinite;
  }

  @keyframes subloaderTextBlink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Subloader = () => {
  return (
    <StyleContainer>
      <div className="text">LOADING...</div>
      <RunningStripes />
    </StyleContainer>
  );
};

export default Subloader;
