import React, { FC } from "react";
import styled from "styled-components";
import RunningStripes from "../../assets/images/RunningStripes";
import { device } from "../../utilities/breakpoints";

const StyledContainer = styled("div")`
  .wrapper {
    width: 100%;
    position: relative;
    padding-right: 60px;
    margin-bottom: 4rem;
    @media ${device.mobileS} {
      padding-right: 0px;
    }
    @media ${device.laptop} {
      padding-right: 60px;
    }
    .title {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      margin-bottom: 1rem;

      h1 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #ffffff;
      }
      .runningStripes {
        margin-left: 2rem;
      }
    }
    .subtitle {
      margin-bottom: 1rem;
      border-bottom: 1px solid #fff500;
      h2 {
        text-transform: uppercase;
        font-weight: 200;
        font-size: 2.8rem;
        line-height: 1.25;
        display: block;
        width: 100%;
        color: #fff500;
      }
    }
    .extras {
      @media ${device.mobileS} {
        display: none;
      }
      @media ${device.laptop} {
        display: block;
      }
      &:before {
        content: "";
        display: block;
        width: 60px;
        height: 1px;
        background-color: #fff500;
        position: absolute;
        right: 0;
        top: calc(100% - 1px);
        transform-origin: top left;
        transform: rotate(45deg);
      }
      > div {
        position: absolute;
        top: calc(100% + 42px);
        right: 2px;
        width: 16px;
        height: 16px;
        animation: HeroTaglinePlusBlinker infinite linear 0.1s;
        &:before,
        &:after {
          content: "";
          display: block;
          width: 16px;
          height: 1px;
          background-color: rgba(255, 255, 255, 0.5);
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -0.5px;
          margin-left: -8px;
        }
        &:after {
          transform: rotate(90deg);
        }
      }
    }
  }
  @keyframes HeroTaglinePlusBlinker {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

interface ISectionTitle {
  title: string;
  subtitle: string;
}

const SectionTitle: FC<ISectionTitle> = (props) => {
  const { title, subtitle } = props;
  return (
    <StyledContainer>
      <div className="wrapper">
        <div className="title">
          <h1>
            <span>{title}</span>
          </h1>
          <RunningStripes />
        </div>
        <div className="subtitle">
          <h2>{subtitle}</h2>
        </div>
        <div className="extras">
          <div />
        </div>
      </div>
    </StyledContainer>
  );
};

export default SectionTitle;
