import React from "react";

function ArrowDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      fill="none"
      viewBox="0 0 19 18"
    >
      <path
        fill="#FFF500"
        fillRule="evenodd"
        d="M18.032 9.274l-7.679 7.755a1.198 1.198 0 01-1.706 0L.967 9.274a1.227 1.227 0 010-1.724 1.198 1.198 0 011.707 0l5.62 5.675V.325h2.413v12.9l5.619-5.675a1.198 1.198 0 011.706 0 1.227 1.227 0 010 1.724z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default ArrowDown;
