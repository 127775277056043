import React, { FC, useCallback, useRef, useState } from "react";
import { IDialog } from "../../common/types/dialog.types";
import styled from "styled-components";
import { useDialogContext } from "../../common/context/DialogContext";
import { useForm } from "react-hook-form";
import { Box, Button, FormGroup, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import contactBg from "../../assets/images/contact_bg.png";
import { useNavigate } from "react-router-dom";
import { device } from "../../utilities/breakpoints";
import plustpattern from "../../assets/images/plus_pattern_yellow.png";
import Logo from "../../assets/images/Logo";
import emailjs from "emailjs-com";
import classNames from "classnames";
import { useWPPageContext } from "../../common/context/WPPageContext";

const contactPatternSize = 22;
const contactPatternSpeed = 1.5;
const StyledContainer = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.mobileS} {
    justify-content: center;
    align-items: flex-start;
  }
  @media ${device.laptop} {
    justify-content: center;
    align-items: center;
  }
  @media ${device.laptopL} {
    justify-content: center;
    align-items: center;
  }

  .wrapper {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    width: min(800px, 100%);
    padding: 2rem;
    position: relative;
    z-index: 10001;
    @media ${device.mobileS} {
      padding: 1rem;
    }
    @media ${device.laptop} {
      padding: 2rem;
    }
  }

  .paper {
    display: block;
    width: 100%;
    color: #1e1e1e;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    border-radius: 2rem;
    overflow: hidden;
    @media ${device.mobileS} {
      border-radius: 1rem;
    }
    @media ${device.laptop} {
      border-radius: 2rem;
    }

    form {
      padding: 2rem;
      background-color: #ffffff;
      @media ${device.mobileS} {
        padding: 1rem;
      }
      @media ${device.mobileL} {
        padding: 1rem;
      }
      @media ${device.laptop} {
        padding: 2rem;
      }
    }
  }
  .logoGrid {
    background-color: #262626;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo {
    width: min(100px, 100%);
  }

  .action {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: flex-end;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background-color: #262626;

    &:after {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;

      background-image: url(${contactBg});
      background-position: center center;
      background-size: cover;
    }

    &:before {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      background-image: url(${plustpattern});
      background-repeat: repeat;
      background-size: ${contactPatternSize}px;
      background-blend-mode: multiply;
      animation: plusjitter infinite ${contactPatternSpeed}s linear;
    }
  }

  .messageBox {
    padding: 3rem;
    background-color: #ffffff;
    h1 {
      font-size: 2rem;
      color: #1e1e1e;
      font-weight: 600;
      line-height: 1.25;
      margin-bottom: 1rem;
    }
    h2 {
      font-size: 1rem;
      color: #262626;
      font-weight: 400;
      line-height: 1.5;
    }
  }

  .clickTip {
    display: inline-block;
    width: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 1rem;
    background-color: rgba(0, 0, 0, 0.85);
    color: #fff500;
    padding: 0.25rem 1rem;
    font-size: 0.6rem;
    border-radius: 1rem;
    text-transform: uppercase;
    font-weight: 400;
  }

  @keyframes plusjitter {
    0% {
      background-position: 0px 0px;
    }
    100% {
      background-position: 0px -${contactPatternSize}px;
    }
  }
`;

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 1rem;
`;

type FormValues = {
  firstName: string;
  lastName: string;
  phone?: number | undefined;
  email: string;
  message: string;
};

const schema = yup
  .object({
    firstName: yup.string().required("Your first name is required."),
    lastName: yup.string().required("Your last name is required."),
    email: yup.string().required("Your email address is required."),
    message: yup.string().required("Your message is required."),
    // phone: yup.number().positive().integer().required(),
  })
  .required();

const ContactForm: FC<IDialog> = (props) => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const { currentRoute } = useWPPageContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });
  const { open } = props;
  const { toggleOpen } = useDialogContext();
  const [done, setDone] = useState(false);
  const [oops, setOops] = useState(false);
  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    toggleOpen();
    navigate(currentRoute);
  };
  const onSubmit = useCallback(() => {
    const currentForm = formRef.current;
    if (currentForm == null) return;
    emailjs
      .sendForm(
        "service_a63hu72",
        "template_0oxxz7e",
        currentForm,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      )
      .then(
        () => {
          console.log("SUCCESS!");
          setDone(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
          setOops(true);
        },
      );
  }, [formRef]);

  return (
    <>
      {open && (
        <StyledContainer>
          <div className="wrapper">
            <div className="paper">
              <Grid container spacing={0}>
                <Grid xs={12} md={2} className="logoGrid">
                  <Logo />
                </Grid>
                <Grid xs={12} md={10}>
                  {!done && !oops && (
                    <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                      <Grid container spacing={2}>
                        <Grid xs={12} md={6}>
                          <StyledFormGroup>
                            <TextField
                              id="outlined-basic"
                              label="First Name"
                              variant="outlined"
                              inputProps={{
                                ...register("firstName", { required: true }),
                              }}
                              aria-invalid={errors.firstName ? "true" : "false"}
                              error={!!errors.firstName}
                              helperText={errors.firstName?.message}
                            />
                          </StyledFormGroup>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <StyledFormGroup>
                            <TextField
                              id="outlined-basic"
                              label="Last Name"
                              variant="outlined"
                              inputProps={{
                                ...register("lastName", { required: true }),
                              }}
                              aria-invalid={errors.lastName ? "true" : "false"}
                              error={!!errors.lastName}
                              helperText={errors.lastName?.message}
                            />
                          </StyledFormGroup>
                        </Grid>
                        <Grid xs={12} md={4}>
                          <StyledFormGroup>
                            <TextField
                              id="outlined-basic"
                              label="Phone Number"
                              variant="outlined"
                              inputProps={{
                                ...register("phone", { required: true }),
                              }}
                              aria-invalid={errors.phone ? "true" : "false"}
                              error={!!errors.phone}
                              helperText={errors.phone?.message}
                            />
                          </StyledFormGroup>
                        </Grid>
                        <Grid xs={12} md={8}>
                          <StyledFormGroup>
                            <TextField
                              id="outlined-basic"
                              label="Email Address"
                              variant="outlined"
                              inputProps={{
                                ...register("email", { required: true }),
                              }}
                              aria-invalid={errors.email ? "true" : "false"}
                              error={!!errors.email}
                              helperText={errors.email?.message}
                            />
                          </StyledFormGroup>
                        </Grid>
                        <Grid xs={12}>
                          <StyledFormGroup>
                            <TextField
                              id="outlined-basic"
                              label="Message"
                              multiline
                              rows={4}
                              variant="outlined"
                              inputProps={{
                                ...register("message", { required: true }),
                              }}
                              aria-invalid={errors.message ? "true" : "false"}
                              error={!!errors.message}
                              helperText={errors.message?.message}
                            />
                          </StyledFormGroup>
                        </Grid>
                        <Grid xs={12} className="action">
                          <Box
                            sx={{ "& > button:not(:last-of-type)": { m: 1 } }}
                          >
                            <Button variant="outlined" onClick={handleClose}>
                              Cancel
                            </Button>
                            <Button type="submit" variant="contained">
                              Send
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                  {done && (
                    <div className={classNames("messageBox thankYou")}>
                      <h1>Thank you for getting in touch!</h1>
                      <h2>
                        We appreciate you contacting AMCSC. One of our
                        colleagues will get back in touch with you soon! Have a
                        great day!
                      </h2>
                    </div>
                  )}
                  {oops && (
                    <div className={classNames("messageBox dang")}>
                      <h1>Oh snap!</h1>
                      <h2>
                        It seems we've encountered some technical difficulty in
                        sending your message. You can try again later. If it
                        still doesn't work, please contact{" "}
                        <span>info@amcsc.net</span>.
                      </h2>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
            <div className={classNames("clickTip")}>
              Click anywhere outside this dialog box to exit
            </div>
          </div>
          <div className="overlay" onClick={handleClose} />
        </StyledContainer>
      )}
    </>
  );
};

export default ContactForm;
