import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SectionTitle from "../common/SectionTitle";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import bg from "../../assets/images/services_bg.png";
import { useWPPageContext } from "../../common/context/WPPageContext";
import ServiceItem from "./ServiceItem";
import parse from "html-react-parser";
import { formatWPRender } from "../../common/utils/format";

const StyledSection = styled("div")`
  background: #05072c;
  width: 100%;
  position: relative;
  z-index: 2;
  overflow: visible;
  padding-top: 8rem;
  padding-bottom: 4rem;
  min-height: 100vh;
  background-image: url(${bg});
  background-size: cover;
  background-attachment: fixed;

  .content {
    padding: 2rem;
    position: relative;
    z-index: 4;
    width: min(1400px, 100%);
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 2rem;
  }

  .gridItemWrapper {
    padding: 2rem;
  }

  .aboutPic {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 2rem;
  }
`;

const ServicesContent = () => {
  const { isLoading: mainLoader, page } = useWPPageContext();
  const [services, setServices] = useState<number[]>([]);

  useEffect(() => {
    if (page) {
      setServices(
        page?.acf?.page_items?.map((item: { ID: number }) => +item.ID),
      );
    }
  }, [page]);
  return (
    <StyledSection>
      {!mainLoader && (
        <div className="content">
          <SectionTitle title={page?.title?.rendered} />

          <Grid container spacing={0}>
            <Grid xs={12} md={12}>
              <div className="gridItemWrapper">
                <div className="wpContentBody">
                  {parse(formatWPRender(page?.content?.rendered))}
                  {/* <img
                  src={page?.acf?.page_image?.url}
                  alt={page?.title?.rendered}
                  className="aboutPic"
                /> */}
                </div>
              </div>
            </Grid>
            <Grid xs={12} md={12}>
              <div className="gridItemWrapper">
                <div className="wpContentBody">
                  <ServiceItem services={services} />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </StyledSection>
  );
};

export default ServicesContent;
