import React, { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { device } from "../../utilities/breakpoints";
import classNames from "classnames";

interface IGrid extends PropsWithChildren {
  wrap?: boolean;
  width?: string;
  grow?: boolean;
  className?: string;
}

const StyledGrid = styled("div")<IGrid>`
  display: flex;
  flex-wrap: ${(props) => (props.wrap ? "wrap" : "nowrap")};
  width: ${(props) => (props.width ? props.width : "100%")};
  max-width: ${(props) => (props.width ? props.width : "100%")};
  flex-grow: ${(props) => (props.grow ? "1" : "0")};
  ${(props) => {
    const { grow, width } = props;
    if (grow && width) {
      return `
        flex-grow: 1;
        width: auto;
      `;
    }
  }}

  @media ${device.mobileS} {
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
  }
  @media ${device.laptop} {
    width: ${(props) => (props.width ? props.width : "100%")};
    max-width: ${(props) => (props.width ? props.width : "100%")};
    flex-wrap: ${(props) => (props.wrap ? "wrap" : "nowrap")};
  }
`;

export const Grid: FC<IGrid> = (props) => {
  const { children, className } = props;
  return (
    <StyledGrid
      className={classNames({
        [`${className}`]: className,
      })}
    >
      {children}
    </StyledGrid>
  );
};

export const GridItem: FC<IGrid> = (props) => {
  const { wrap, width, grow, children, className } = props;
  return (
    <StyledGrid
      className={classNames({
        [`${className}`]: className,
      })}
      wrap={wrap}
      width={width}
      grow={grow}
    >
      {children}
    </StyledGrid>
  );
};
