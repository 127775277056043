import classNames from "classnames";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/images/Logo";
import { ROUTES, PAGE_LABELS } from "../../common/router/constants";
import styled from "styled-components";
import { useScrollEvent } from "../../common/context/EventsContext";
import { device } from "../../utilities/breakpoints";
import { isCurrentPage } from "../../common/utils/isCurrentPage";
import {
  defaultPageValue,
  useWPPageContext,
} from "../../common/context/WPPageContext";

const StyledHeader = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  min-height: 100px;
  background-image: linear-gradient(
    to bottom,
    rgba(30, 30, 30, 1),
    rgba(30, 30, 30, 0)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-out;
  &.scrolled {
    background-color: rgba(30, 30, 30, 0.75);
    min-height: 80px;
    ul {
      grid-template-rows: 60px;
      li.logo a {
        width: 60px;
        min-width: 60px;
      }
    }
  }
  nav {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  ul {
    display: inline-grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(1, 1fr);
    width: auto;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease-out;
    @media ${device.mobileS} {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 60px 2rem;
    }
    @media ${device.laptop} {
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: 80px;
    }
    list-style: none;
    li {
      margin: 0 10px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-out;
      @media ${device.mobileS} {
        height: 2rem;
        margin: 0;
        &:nth-child(1) {
          grid-column: 1;
          grid-row: 2;
        }
        &:nth-child(2) {
          grid-column: 2;
          grid-row: 2;
        }
        &:nth-child(4) {
          grid-column: 3;
          grid-row: 2;
        }
        &:nth-child(5) {
          grid-column: 4;
          grid-row: 2;
        }
      }
      @media ${device.laptop} {
        height: auto;
        margin: 0 10px;
        &:nth-child(1) {
          grid-column: 1;
          grid-row: 1;
        }
        &:nth-child(2) {
          grid-column: 2;
          grid-row: 1;
        }
        &:nth-child(4) {
          grid-column: 4;
          grid-row: 1;
        }
        &:nth-child(5) {
          grid-column: 5;
          grid-row: 1;
        }
      }

      a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 10px;
        color: rgba(255, 255, 255, 0.25);
        text-decoration: none;
        outline: none;
        transition: all 0.3s ease-out;

        &:hover {
          color: rgba(255, 255, 255, 1);
        }
        &.current {
          color: #fff500;
        }
      }

      &.logo {
        @media ${device.mobileS} {
          height: 4rem;
          grid-column: 1 / span 4;
          grid-row: 1;
          width: 100%;
        }
        @media ${device.laptop} {
          height: auto;
          grid-column: 3;
          grid-row: 1;
        }
        a {
          margin: 0 20px;
          width: 80px;
          min-width: 80px;
          @media ${device.mobileS} {
            width: 60px;
            min-width: 60px;
          }
          @media ${device.laptop} {
            width: 80px;
            min-width: 80px;
          }
        }
      }
    }
  }
`;

const Header = () => {
  const { scrolled } = useScrollEvent();
  const { pathname } = useLocation();
  const { isLoading, setPage, currentRoute, setCurrentRoute } =
    useWPPageContext();

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    const targetPath = e.currentTarget.getAttribute("href");
    if (targetPath && targetPath.indexOf(ROUTES.CONTACT) <= -1) {
      setCurrentRoute(targetPath);
    }
    const isSamePage = targetPath ? targetPath?.indexOf(pathname) > -1 : false;
    if (isLoading && !isSamePage) e.preventDefault();
    if (!isSamePage && targetPath) {
      setPage(defaultPageValue.page);
    }
  };

  return (
    <StyledHeader
      className={classNames({
        scrolled: scrolled,
      })}
    >
      <nav>
        <ul>
          <li>
            <Link
              className={classNames({
                current: isCurrentPage(ROUTES.ABOUT, pathname),
              })}
              to={ROUTES.ABOUT}
              onClick={handleOnClick}
            >
              {PAGE_LABELS.ABOUT}
            </Link>
          </li>
          <li>
            <Link
              className={classNames({
                current: isCurrentPage(ROUTES.SERVICES, pathname),
              })}
              to={ROUTES.SERVICES}
              onClick={handleOnClick}
            >
              {PAGE_LABELS.SERVICES}
            </Link>
          </li>
          <li className="logo">
            <Link
              className={classNames({
                current: isCurrentPage(ROUTES.HOME, pathname),
              })}
              to={ROUTES.HOME}
              onClick={handleOnClick}
            >
              <Logo />
            </Link>
          </li>
          <li>
            <Link
              className={classNames({
                current: isCurrentPage(ROUTES.PROJECTS, pathname),
              })}
              to={ROUTES.PROJECTS}
              onClick={handleOnClick}
            >
              {PAGE_LABELS.PROJECTS}
            </Link>
          </li>
          <li>
            <Link
              className={classNames({
                current: isCurrentPage(ROUTES.CONTACT, pathname),
              })}
              to={ROUTES.CONTACT}
              onClick={handleOnClick}
            >
              {PAGE_LABELS.CONTACT}
            </Link>
          </li>
        </ul>
      </nav>
    </StyledHeader>
  );
};

export default Header;
