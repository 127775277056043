import React from "react";
import styled from "styled-components";
import PreloaderSVG from "../../assets/images/PreloaderSVG";
import Logo from "../../assets/images/Logo";
import plustpattern from "../../assets/images/plus_pattern_yellow.png";

const preloaderPlustSize = 22;
const preloaderPlusJitterSpeed = 1.5;
const StyleContainer = styled("div")`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #1e1e1e;
  position: relative;
  z-index: 1;

  .wrapper {
    width: 130px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .logo {
    width: 90px;
    height: 90px;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 400px;
    border: 7px solid #fff500;
    background-image: url(${plustpattern});
    background-repeat: repeat;
    background-size: ${preloaderPlustSize}px;
    background-blend-mode: multiply;
    animation: preloaderLogoPlusJitter infinite ${preloaderPlusJitterSpeed}s
      linear;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow:
      inset 0 0 20px 5px rgba(0, 0, 0, 1),
      0 0 14px 0 rgba(0, 0, 0, 1);
    svg {
      width: 50px;
      height: 50px;
    }
  }
  .preloaderSVG {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    z-index: 1;
    > svg {
      width: 100%;
      height: 100%;
    }
  }
  .text {
    font-size: 8px;
    letter-spacing: 2px;
    animation: preloaderTextBlink 0.1s linear infinite;
  }

  @keyframes preloaderTextBlink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes preloaderLogoPlusJitter {
    0% {
      background-position: 0px 0px;
    }
    100% {
      background-position: 0px -${preloaderPlustSize}px;
    }
  }
`;

const Preloader = () => {
  return (
    <StyleContainer>
      <div className="wrapper">
        <Logo />
        <PreloaderSVG />
      </div>
      <div className="text">LOADING</div>
    </StyleContainer>
  );
};

export default Preloader;
