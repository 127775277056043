import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { router } from "./common/router";
import { ScrollEventProvider } from "./common/context/EventsContext";
import { WPPageProvider } from "./common/context/WPPageContext";
import { DialogProvider } from "./common/context/DialogContext";

const root = document.getElementById("root")!;

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <ScrollEventProvider>
      <WPPageProvider>
        <DialogProvider>
          <RouterProvider router={router} />
        </DialogProvider>
      </WPPageProvider>
    </ScrollEventProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
