import React from "react";
import styled from "styled-components";
import plustpattern from "../../assets/images/plus_pattern.png";
import Logo from "../../assets/images/Logo";
import { device } from "../../utilities/breakpoints";

const StyledContainer = styled("div")`
  background-image: url(${plustpattern});
  background-repeat: repeat;
  background-size: 30px;
  background-color: #fff500;
  background-blend-mode: multiply;
  background-position: 0px 0px;
  padding: 4rem 4rem 0 4rem;
  bottom: 0;
  left: 0;
  position: relative;
  z-index: 0;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-image: linear-gradient(
      to bottom,
      rgba(30, 30, 30, 1),
      rgba(30, 30, 30, 0)
    );
  }

  animation: footerBackgroundPlusJitter infinite 5s linear;
  @media ${device.mobileS} {
    position: relative;
    padding: 4rem 2rem 0 2rem;
  }
  @media ${device.laptop} {
    padding: 4rem 4rem 0 4rem;
  }
  @media ${device.laptopL} {
    position: sticky;
  }

  .wrapper {
    background-color: rgb(30, 30, 30);
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 40%
    );
    padding: 4rem 4rem 0 4rem;
    margin-right: auto;
    margin-left: auto;
    min-height: 400px;
    width: min(1400px, 100%);
    border-top-left-radius: 4rem;
    border-top-right-radius: 4rem;
    position: relative;
    @media ${device.mobileS} {
      padding: 4rem 2rem 0 2rem;
    }
    @media ${device.laptop} {
      padding: 4rem 4rem 0 4rem;
    }
  }

  .logo {
    position: absolute;
    top: -1.5rem;
    left: 4rem;
    width: 140px;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 1));
    @media ${device.mobileS} {
      left: 50%;
      margin-left: -70px;
    }
    @media ${device.laptop} {
      left: 4rem;
      margin-left: 0;
    }
  }

  .content {
    margin-left: 12rem;
    margin-bottom: 4rem;
    @media ${device.mobileS} {
      margin-top: 8rem;
      margin-left: 0;
    }
    @media ${device.laptop} {
      margin-top: 0;
      margin-left: 12rem;
    }

    h1 {
      color: #ffffff;
      font-size: 1.5rem;
      margin-bottom: 2rem;
      padding: 0 2rem;
      @media ${device.mobileS} {
        padding: 0;
      }
      @media ${device.laptop} {
        padding: 0 2rem;
      }
    }
    h3 {
      font-size: 1rem;
      font-weight: 900;
    }
    div {
      margin-bottom: 1rem;
      > span {
        display: block;
      }
    }

    a {
      color: #fff500;
      text-decoration: none;
    }
  }

  .grid {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;

    @media ${device.mobileS} {
      flex-wrap: wrap;
    }
    @media ${device.laptop} {
      flex-wrap: nowrap;
    }

    .item {
      width: 50%;
      @media ${device.mobileS} {
        width: 100%;
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
      @media ${device.laptop} {
        width: 50%;
      }
    }
    .itemWrapper {
      padding: 0 2rem;
      @media ${device.mobileS} {
        padding: 0;
      }
      @media ${device.laptop} {
        padding: 0 2rem;
      }
    }
  }

  .copyright {
    position: relative;
    padding: 2rem;
    text-align: center;
    color: rgba(255, 255, 255, 0.3);
    font-size: 12px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  @keyframes footerBackgroundPlusJitter {
    0% {
      background-position: 0px 0px;
    }
    100% {
      background-position: 0px 30px;
    }
  }
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <StyledContainer>
      <div className="wrapper">
        <div className="logo">
          <Logo />
        </div>
        <div className="content">
          <h1>Atlas Management &amp; Construction Services Corp.</h1>
          <div className="grid">
            <div className="item">
              <div className="itemWrapper">
                <h3>AMCSC Main Office</h3>
                <div className="address">
                  <div>
                    <span>Freestar Arcade, Building I</span>
                    <span>Hernan Cortes St., Subangdaku,</span>
                    <span>Mandaue City, Cebu</span>
                  </div>
                </div>
                <div className="phone">
                  <div>
                    <span>Tel No: (032) 345 9884 / (032) 344 3676</span>
                    <span>Fax No: (032) 346 9265</span>
                    <span>
                      Email:{" "}
                      <a href="mailto:atlas@amcsc.net">atlas@amcsc.net</a>
                    </span>
                  </div>
                </div>
                <div className="contact">
                  <div>
                    <span>Contacts: </span>
                    <ul>
                      <li>Engr. Emmanuel V. Calderon Jr.</li>
                      <li>Ismael Aldrin T. Abregonde</li>
                      <li>Ar. Emmanuel Jette III C. Calderon</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="itemWrapper">
                <h3>AMCSC Fabrication Shop & Warehouse</h3>
                <div className="address">
                  <p>
                    <span>Cogon, Compostela, Cebu 6003</span>
                  </p>
                </div>
                <div className="phone">
                  <p>
                    <span>Tel No: (032) 460 0991</span>
                  </p>
                </div>
                <div className="contact">
                  <div>
                    <span>Contacts:</span>
                    <ul>
                      <li>Myrna Hamili</li>
                      <li>Fredderick Umbrete</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>
            Copyright {currentYear} &copy; Atlas Management &amp; Construction
            Services, Corp. - All Rights Reserved.
          </p>
        </div>
      </div>
    </StyledContainer>
  );
};

export default Footer;
