export const fetchContent = async (route: string) => {
  const baseURL = `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_NAMESPACE}`;
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const fetchOptions = {
    method: "GET",
    headers: headers,
  };
  const endpoint = `${baseURL}/${route}`;
  let response;
  await fetch(endpoint, fetchOptions)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new Error("Something went wrong");
    })
    .then((responseJson) => {
      response = responseJson;
    })
    .catch((err) => console.log(err));
  return response;
};
