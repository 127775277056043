import React from "react";
import Layout from "../components/common/Layout";
import ProjectsContent from "../components/Projects/ProjectsContent";
import { useWPPageApi } from "../common/api/useWPPageApi";
import { API_ROUTES } from "../common/api/apiRoutes";

const Projects = () => {
  const endpoint = API_ROUTES.PROJECTS_PAGE;
  const { isLoading, data } = useWPPageApi(endpoint);
  return (
    <Layout isLoading={isLoading}>
      <ProjectsContent data={data} />
    </Layout>
  );
};

export default Projects;
