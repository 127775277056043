import React, { FC, useEffect, useState } from "react";
import { API_ROUTES } from "../../common/api/apiRoutes";
import { fetchContent } from "../../common/api/fetchContent";
import parse from "html-react-parser";
import { formatWPRender } from "../../common/utils/format";
import Subloader from "../common/Subloader";

interface IServiceItem {
  services: number[];
}
const ServiceItem: FC<IServiceItem> = ({ services }) => {
  const [content, setContent] = useState<unknown[]>([]);
  const [isLoading, setIsloading] = useState(true);
  useEffect(() => {
    if (services) {
      (async () => {
        setIsloading(true);
        const endpoints = services.map((id) => {
          return `${API_ROUTES.SERVICE_DETAILS}/${id}`;
        });
        const responses = await Promise.all(endpoints.map(fetchContent));
        setContent(responses);
      })();
    }
  }, [services]);

  useEffect(() => {
    setIsloading(!content);
  }, [content]);
  return (
    <div>
      {isLoading ? (
        <Subloader />
      ) : (
        <>
          {content?.map((item: any, key: number) => (
            <div className="wpContentBodySection" key={key}>
              <h1>{parse(formatWPRender(item?.title?.rendered))}</h1>
              {parse(formatWPRender(item?.content?.rendered))}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ServiceItem;
