import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

interface IEventContextProvider {
  children?: ReactNode;
}

type TScrollEventContext = {
  scrolled: boolean;
  posY: number;
};

const ScrollEventContext = createContext<TScrollEventContext>({
  scrolled: false,
  posY: 0,
});

export const ScrollEventProvider: FC<IEventContextProvider> = ({
  children,
}) => {
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [posY, setPosY] = useState<number>(0);

  const handleScroll = () => {
    const pos = (window.scrollY / 300) * 100;
    setScrolled(pos >= 100);
    setPosY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ScrollEventContext.Provider value={{ scrolled, posY }}>
      {children}
    </ScrollEventContext.Provider>
  );
};

export const useScrollEvent = () => {
  return useContext(ScrollEventContext);
};
