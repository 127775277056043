import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SectionTitle from "../common/SectionTitle";
import aboutbg from "../../assets/images/about_bg.png";
import { useWPPageContext } from "../../common/context/WPPageContext";
import { IPage } from "../../common/types/pages.types";
import parse from "html-react-parser";
import { formatWPRender } from "../../common/utils/format";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import SolidButton from "../common/Button2";
import SearchIcon from "@mui/icons-material/Search";
import { Dialog, useMediaQuery, useTheme } from "@mui/material";
import classNames from "classnames";

const StyledSection = styled("div")`
  background: #262626;
  width: 100%;
  position: relative;
  z-index: 2;
  overflow: visible;
  padding-top: 8rem;
  padding-bottom: 2rem;
  min-height: 100vh;
  background-image: url(${aboutbg});
  background-size: cover;
  background-attachment: fixed;

  .content {
    padding: 2rem;
    position: relative;
    z-index: 4;
    width: min(1400px, 100%);
    margin-left: auto;
    margin-right: auto;
  }

  .gridItemWrapper {
    padding: 2rem;

    .orgChart {
      margin-top: 2rem;
    }
  }

  .aboutPic {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 2rem;
  }

  .orgChartDialogContent {
    width: min(1200px, 100%);
    height: auto;
  }
`;

const AboutContent = () => {
  const theme = useTheme();
  const { page } = useWPPageContext();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledSection>
      <div className="content">
        <SectionTitle title={page?.title?.rendered} />
        <Grid container spacing={0}>
          <Grid xs={12} md={5}>
            <div className="gridItemWrapper">
              <img
                src={page?.acf?.page_image?.url}
                alt="about amcsc"
                className="aboutPic"
              />
            </div>
          </Grid>
          <Grid xs={12} md={7}>
            <div className="gridItemWrapper">
              <div className="wpContentBody">
                {parse(formatWPRender(page?.content?.rendered))}
              </div>
              <div className="orgChart">
                <Dialog
                  open={open}
                  fullWidth={fullScreen}
                  maxWidth="xl"
                  onClose={handleClose}
                  sx={{
                    "& .MuiDialog-container": {},
                    "& .MuiPaper-root": {
                      background: "#262626",
                      padding: "2rem",
                    },
                    "& .MuiBackdrop-root": {
                      backgroundColor: "rgba(0,0,0,0.85)", // Try to remove this to see the result
                    },
                    "& .orgChartDialogContent img": {
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                      "& > img": {
                        width: "100%",
                        height: "auto",
                      },
                    },
                  }}
                >
                  <div className={classNames("orgChartDialogContent")}>
                    <img
                      src={page?.acf?.org_chart_image?.url}
                      alt={page?.title?.rendered}
                    />
                  </div>
                </Dialog>
                <SolidButton
                  to="#"
                  label="View Organization Chart"
                  icon={<SearchIcon />}
                  onClick={handleOpen}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </StyledSection>
  );
};

export default AboutContent;
