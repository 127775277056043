import React from "react";
import styled from "styled-components";
import section2bg from "../../assets/images/section1_bg.png";
import classNames from "classnames";
import { device } from "../../utilities/breakpoints";
import { ROUTES } from "../../common/router/constants";
import RunningStripes from "../../assets/images/RunningStripes";
import Button from "../common/Button";
import { useWPPageContext } from "../../common/context/WPPageContext";
import parse from "html-react-parser";
import { formatWPRender } from "../../common/utils/format";

const StyledSection = styled("div")`
  background: #1e1e1e;
  background-image: url(${section2bg});
  background-position: top center;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  padding: 2rem;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  padding-bottom: 30vh;
  @media ${device.mobileS} {
    padding-bottom: 5vh;
  }
  @media ${device.laptop} {
    padding-bottom: 30vh;
  }

  .grid {
    width: min(100%, 1400px);
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 3;

    .item {
      width: 100%;
      padding: 2rem;
      @media ${device.mobileS} {
        text-align: center;
      }
      @media ${device.laptop} {
        text-align: left;
      }
    }

    .right {
      flex-grow: 1;
      @media ${device.mobileS} {
        width: 100%;
      }
      @media ${device.laptop} {
        width: min(50%, calc(100% - 800px));
      }
      .runningStripes {
        margin-bottom: 3rem;
        @media ${device.mobileS} {
          margin-left: auto;
          margin-right: auto;
        }
        @media ${device.laptop} {
          margin-left: inherit;
          margin-right: inherit;
        }
      }
      h1,
      h2 {
        text-transform: uppercase;
        line-height: 1.2;
      }
      h2 {
        color: #ffffff;
        font-weight: 600;
        font-size: 1.5rem;
        margin-bottom: 0.6rem;
      }
      h1 {
        color: #fff500;
        font-weight: 900;
        font-size: 2.5rem;
        margin-bottom: 1rem;
      }
      p {
        margin-bottom: 3rem;
      }
    }
    .left {
      display: flex;
      justify-content: flex-end;
      @media ${device.mobileS} {
        width: 100%;
      }
      @media ${device.laptop} {
        width: min(50%, 800px);
      }
      img {
        width: min(600px, 100%);
        height: max(600px, auto);
        object-fit: cover;
        border-radius: 3rem;
        box-shadow: 0 10px 2rem 0 rgba(0, 0, 0, 0.75);

        @media ${device.mobileS} {
          margin-left: auto;
          margin-right: auto;
        }
        @media ${device.laptop} {
          margin-left: inherit;
          margin-right: inherit;
        }
      }
    }
  }
`;

const HomeServices = () => {
  const { page } = useWPPageContext();
  return (
    <StyledSection>
      <div className={classNames("grid")}>
        <div className={classNames("item left")}>
          <img
            src={page?.acf?.service_section_image?.url}
            alt="planning something"
          />
        </div>
        <div className={classNames("item right")}>
          <div className="wpContentBody">
            <h2>{page?.acf?.service_section_title}</h2>
            <h1>{page?.acf?.service_section_subtitle}</h1>
            <RunningStripes />
            {parse(formatWPRender(page?.acf.service_section_content))}
            <Button to={ROUTES.SERVICES} label="Learn More" />
          </div>
        </div>
      </div>
    </StyledSection>
  );
};

export default HomeServices;
