import React, { useEffect } from "react";
import Layout from "../components/common/Layout";
import ServicesContent from "../components/Services/ServicesContent";
import { API_ROUTES } from "../common/api/apiRoutes";
import { useWPPageApi } from "../common/api/useWPPageApi";
import { useWPPageContext } from "../common/context/WPPageContext";

const Services = () => {
  const { isLoading, data } = useWPPageApi(API_ROUTES.SERVICES_PAGE);
  const { setPage } = useWPPageContext();

  useEffect(() => {
    if (!isLoading && data) setPage(data);
  }, [setPage, isLoading, data]);
  return (
    <Layout isLoading={isLoading}>
      <ServicesContent />
    </Layout>
  );
};

export default Services;
