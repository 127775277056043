interface IROUTES {
  [key: string]: string;
}

export const ROUTES: IROUTES = {
  HOME: "/",
  ABOUT: "/about",
  SERVICES: "/services",
  PROJECTS: "/projects",
  CONTACT: "/contact",
};

export const PAGE_LABELS = {
  HOME: "Home",
  ABOUT: "About",
  SERVICES: "Services",
  PROJECTS: "Projects",
  CONTACT: "Contact",
};

export const PAGE_TITLES = {
  HOME: "Home",
  ABOUT: "About Us",
  SERVICES: "Our Services",
  PROJECTS: "Projects",
  CONTACT: "Contact Us",
};
