import { useEffect, useState } from "react";
import { useWPPageContext } from "../context/WPPageContext";
import { useScrollEvent } from "../context/EventsContext";

export const useWPPageApi = (apiRoute: string, store: boolean = true) => {
  const { isLoading, setIsLoading, page, setPage, subLoading, setSubLoading } =
    useWPPageContext();
  const [error, setError] = useState<unknown>();
  const [data, setData] = useState<any>();
  const baseURL = `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_NAMESPACE}`;

  const endpoint = `${baseURL}/${apiRoute}`;

  useEffect(() => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const fetchOptions = {
      method: "GET",
      headers: headers,
    };
    (async () => {
      if (store) {
        setIsLoading(true);
      }
      setSubLoading(true);
      await fetch(endpoint, fetchOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Something went wrong");
        })
        .then((responseJson) => {
          const result =
            responseJson.length > 0 ? responseJson[0] : responseJson;
          if (store) {
            setPage(result);
          }
          setData(result);
          if (store) setIsLoading(false);
          setSubLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setError(error);
          if (store) {
            setIsLoading(false);
          }
          setSubLoading(false);
        });
    })();
  }, [endpoint, setIsLoading, setPage, store, setSubLoading]);

  return {
    isLoading,
    subLoading,
    data,
    page,
    error,
  };
};
