import React from "react";

import stripes from "./stripes.png";
import styled from "styled-components";
import classNames from "classnames";

const StyledContainer = styled("div")`
  width: 180px;
  height: 25px;
  overflow: hidden;
  position: relative;

  img {
    width: 250px !important;
    height: 25px !important;
    max-width: none;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
    animation: runningStripes infinite 1s linear;
  }

  @keyframes runningStripes {
    0% {
      transform: translatex(0);
    }
    100% {
      transform: translatex(-24px);
    }
  }
`;

const RunningStripes = () => {
  return (
    <StyledContainer className={classNames("runningStripes")}>
      <img src={stripes} alt="running stripes" />
    </StyledContainer>
  );
};

export default RunningStripes;
