import React from "react";
import styled from "styled-components";
import section3bg from "../../assets/images/section3_bg.png";
import { device } from "../../utilities/breakpoints";
import { ROUTES } from "../../common/router/constants";
import IconArrowRight from "../../assets/images/IconArrowRight";
import SolidButton from "../common/Button2";
import RunningStripes from "../../assets/images/RunningStripes";
import { useWPPageContext } from "../../common/context/WPPageContext";
import parse from "html-react-parser";
import { formatWPRender } from "../../common/utils/format";

const StyledSection = styled("div")`
  position: relative;
  z-index: 2;
  background-color: #262626;
  background-image: url(${section3bg});
  background-position: top center;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 200px;
  padding-bottom: 4rem;
  @media ${device.mobileS} {
    padding-top: 100px;
  }
  @media ${device.laptop} {
    padding-top: 200px;
  }

  .wrapper {
    margin-left: auto;
    margin-right: auto;
    width: min(1350px, 100%);
    padding-right: 50px;
    position: relative;
    @media ${device.mobileS} {
      padding-right: 2rem;
      padding-left: 2rem;
    }
    @media ${device.laptop} {
      padding-right: 50px;
    }
  }

  .link {
    display: flex;
    justify-content: flex-end;
  }

  .grid {
    display: grid;
    grid-template-columns: auto 30%;
    grid-template-rows: 1fr;
    background-color: #ffffff;
    border-radius: 2rem;
    overflow: hidden;
    @media ${device.mobileS} {
      grid-template-columns: 1fr;
      grid-template-rows: 200px 1fr;
    }
    @media ${device.laptop} {
      grid-template-columns: auto 30%;
      grid-template-rows: 1fr;
    }
  }

  .gridWrapper {
    width: 100%;
    padding: 2rem;

    .wpContentBody {
      color: #000000;
      h1 {
        color: #000000;
      }

      p {
        color: #1c1c1c;
      }
    }
  }

  .left {
    grid-column: 1;
    grid-row: 1;
    .gridWrapper {
      padding: 6rem;
    }
    @media ${device.mobileS} {
      grid-column: 1;
      grid-row: 2;
      .gridWrapper {
        padding: 2rem;
      }
    }
    @media ${device.laptop} {
      grid-column: 1;
      grid-row: 1;
      .gridWrapper {
        padding: 6rem;
      }
    }
  }

  .right {
    grid-column: 2;
    grid-row: 1;
    @media ${device.mobileS} {
      grid-column: 1;
      grid-row: 1;
    }
    @media ${device.laptop} {
      grid-column: 2;
      grid-row: 1;
    }
    .gridWrapper {
      height: 100%;
      padding: 0;
      > img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
  }

  .gfx {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    position: absolute;
    left: calc(100% - 12px);
    top: 2rem;
    width: 500px;
    transform-origin: top left;
    transform: rotate(90deg);

    @media ${device.mobileS} {
      display: none;
    }
    @media ${device.laptop} {
      display: flex;
    }

    > p {
      margin-left: 1rem;
      text-transform: uppercase;
      color: #ffffff;
      letter-spacing: 0.1rem;
      font-size: 12px;
      display: inline-block;
    }
  }
`;

const HomeContact = () => {
  const { page } = useWPPageContext();
  return (
    <StyledSection>
      <div className="wrapper">
        <div className="grid">
          <div className="gridItem left">
            <div className="gridWrapper">
              <div className="wpContentBody">
                <h1>{page?.acf.contact_section_title}</h1>
                {parse(formatWPRender(page?.acf.contact_section_content))}
              </div>
            </div>
            <div className="link">
              <SolidButton
                to={ROUTES.CONTACT}
                icon={<IconArrowRight />}
                label="Contact Us"
              />
            </div>
          </div>
          <div className="gridItem right">
            <div className="gridWrapper">
              <img
                src={decodeURI(page?.acf?.contact_section_image?.url)}
                alt={page?.acf.contact_section_title}
              />
            </div>
          </div>
        </div>
        <div className="gfx">
          <RunningStripes />
          <p>Tradition + Innovation</p>
        </div>
      </div>
    </StyledSection>
  );
};

export default HomeContact;
