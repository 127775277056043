import React from "react";
import styled from "styled-components";

const StyledContainer = styled("div")`
  svg {
    overflow: visible;
  }
  path {
    transform-origin: center;
    transform-box: fill-box;
    transform: translate3d(0, 0, 0);
    /* -webkit-backface-visibility: hidden;
    backface-visibility: hidden; */
    animation: spin_full 2s linear infinite;
  }

  @keyframes spin_full {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const PreloaderSVG = () => {
  return (
    <StyledContainer className="preloaderSVG">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="270"
        height="270"
        fill="none"
        viewBox="0 0 270 270"
      >
        <path
          fill="#FFF500"
          d="M88.574 3.72c3.905 3.906 3.905 10.238 0 14.143l-70.71 70.71c-3.906 3.906-10.238 3.906-14.143 0-3.905-3.905-3.905-10.236 0-14.141L74.43 3.72c3.906-3.905 10.237-3.905 14.143 0z"
        ></path>
        <path
          fill="#FFF500"
          d="M11 71c5.523 0 10 4.477 10 10v100c0 5.523-4.477 10-10 10s-10-4.477-10-10V81c0-5.523 4.477-10 10-10z"
        ></path>
        <path
          fill="#FFF500"
          d="M3.72 173.426c3.906-3.905 10.238-3.905 14.143 0l70.71 70.711c3.906 3.905 3.906 10.237 0 14.142-3.904 3.905-10.236 3.905-14.141 0L3.72 187.569c-3.905-3.906-3.905-10.237 0-14.143z"
        ></path>
        <path
          fill="#FFF500"
          d="M71 251c0-5.523 4.477-10 10-10h100c5.523 0 10 4.477 10 10s-4.477 10-10 10H81c-5.523 0-10-4.477-10-10z"
        ></path>
        <path
          fill="#FFF500"
          d="M258.279 173.426c3.906 3.906 3.906 10.237 0 14.143l-70.71 70.71c-3.906 3.905-10.237 3.905-14.143 0-3.905-3.905-3.905-10.237 0-14.142l70.711-70.711c3.905-3.905 10.237-3.905 14.142 0z"
        ></path>
        <path
          fill="#FFF500"
          d="M251 71c5.523 0 10 4.477 10 10v100c0 5.523-4.477 10-10 10s-10-4.477-10-10V81c0-5.523 4.477-10 10-10z"
        ></path>
        <path
          fill="#FFF500"
          d="M173.426 3.72c3.906-3.904 10.237-3.904 14.143 0l70.71 70.712c3.906 3.905 3.906 10.236 0 14.142-3.905 3.905-10.237 3.905-14.142 0l-70.711-70.711c-3.905-3.905-3.905-10.237 0-14.142z"
        ></path>
        <path
          fill="#FFF500"
          d="M71 11c0-5.523 4.477-10 10-10h100c5.523 0 10 4.477 10 10s-4.477 10-10 10H81c-5.523 0-10-4.477-10-10z"
        ></path>
      </svg>
    </StyledContainer>
  );
};

export default PreloaderSVG;
