import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import React, { FC } from "react";
import styled from "styled-components";

const StyledContainer = styled("div")`
  .imageWrapper {
    img {
      display: block;
      margin: 0;
      padding: 0;
      width: 100%;
      height: auto;
      line-height: 0;
    }
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
`;

interface IProjectGalleryData {
  url: string | undefined;
  thumbnail: string | undefined;
}
interface IProjectGallery {
  gallery: IProjectGalleryData[];
}

const ProjectGallery: FC<IProjectGallery> = ({ gallery }) => {
  return (
    <StyledContainer>
      <Grid container spacing={0} gap={2}>
        {gallery &&
          gallery.map(
            (item: IProjectGalleryData, key: number) =>
              item.url && (
                <Grid xs={12} key={key} className="imageWrapper">
                  <img src={item.url} alt={`gallery ${key}`} />
                </Grid>
              ),
          )}
      </Grid>
    </StyledContainer>
  );
};

export default ProjectGallery;
