import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import { Link, useLocation } from "react-router-dom";
import ContactForm from "../Contact/ContactForm";
import { useDialogContext } from "../../common/context/DialogContext";
import { isCurrentPage } from "../../common/utils/isCurrentPage";
import { ROUTES } from "../../common/router/constants";
import Preloader from "./Preloader";
import classNames from "classnames";
import ArrowUp from "../../assets/images/ArrowUp";
import { device } from "../../utilities/breakpoints";
import { useScrollEvent } from "../../common/context/EventsContext";

const StyledLayout = styled("div")`
  background-color: #1e1e1e;
  width: 100%;
  min-height: 100vh;

  .toTop {
    position: fixed;
    bottom: 1rem;
    right: -36px;
    padding: 1rem;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    transform: scale(0.8);
    transform-origin: bottom right;
    transition: all 0.15s ease-out;
    border-radius: 1rem 0 0 1rem;
    display: none;
    &:hover {
      right: 0;
      transform: scale(1);
    }
    @media ${device.mobileS} {
      right: 0px;
      transform: scale(1);
    }
    @media ${device.tablet} {
      right: 0px;
      transform: scale(1);
    }
    @media ${device.laptop} {
      right: -36px;
      transform: scale(0.8);
    }
    &.showTop {
      display: block;
    }
    a {
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 60px;
      cursor: pointer;
      > p {
        display: block;
        margin: 0 0 1rem 0;
        padding: 0;
        color: #fff500;
        text-align: center;
        font-size: 0.7rem;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.4rem;
        transition: all 0.1s ease-out;
        line-height: 1;
      }
      > div {
        width: 59px;
        height: 59px;
        border-radius: 60px;
        border: 1px solid #fff500;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.1s ease-out;
        transform-origin: center center;
        box-shadow: 0 0 1rem 0 rgba(255, 255, 255, 0);
      }

      &:hover {
        > p {
          color: #ffffff;
        }
        > div {
          transform: scale(0.9);
          border-color: #ffffff;
          box-shadow: 0 0 1rem 0 rgba(255, 255, 255, 0.5);
        }
        svg > path {
          fill: #ffffff;
        }
      }
    }
  }
`;

interface ILayout extends PropsWithChildren {
  isLoading?: boolean;
  children?: ReactNode;
}

const Layout: FC<ILayout> = ({ isLoading, children }) => {
  const { pathname } = useLocation();
  const { open, setOpen } = useDialogContext();
  const { posY } = useScrollEvent();
  const [showTop, setShowTop] = useState(false);

  const handleToTop = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    setOpen(isCurrentPage(ROUTES.CONTACT, pathname));
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname, setOpen]);

  useEffect(() => {
    setShowTop(posY > window.innerHeight);
  }, [posY]);

  useEffect(() => {
    console.log("**** NODE ENV", process.env.REACT_APP_NODE_ENV);
  }, []);

  return (
    <StyledLayout
      className={classNames({
        isLoading: isLoading,
      })}
    >
      <Header />
      {isLoading ? <Preloader /> : children}
      <Footer />

      <div
        className={classNames("toTop", {
          showTop: showTop,
        })}
      >
        <Link to="#" onClick={handleToTop}>
          <p>TOP</p>
          <div>
            <ArrowUp />
          </div>
        </Link>
      </div>
      {open && <ContactForm open={open} type="dialog" />}
    </StyledLayout>
  );
};

export default Layout;
