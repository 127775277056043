import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useState,
} from "react";
import { IPage } from "../types/pages.types";
import { ROUTES } from "../router/constants";

interface IWPPageProvider {
  children?: ReactNode;
}

type WPPageContextType = {
  page: IPage<any>;
  setPage: (e: IPage<any>) => void;
  isLoading: boolean;
  setIsLoading: (e: boolean) => void;
  subLoading: boolean;
  setSubLoading: (e: boolean) => void;
  currentRoute: string;
  setCurrentRoute: (e: string) => void;
};

export const defaultPageValue = {
  page: {
    id: 0,
    content: {
      rendered: "",
    },
    title: {
      rendered: "",
    },
    excerpt: {
      rendered: "",
    },
    acf: {},
  },
  setPage: () => {},
  isLoading: true,
  setIsLoading: () => {},
  subLoading: true,
  setSubLoading: () => {},
  currentRoute: ROUTES.HOME,
  setCurrentRoute: () => {},
};

const WPPageContext = createContext<WPPageContextType>(defaultPageValue);

export const WPPageProvider: FC<IWPPageProvider> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [subLoading, setSubLoading] = useState<boolean>(true);
  const [page, setPage] = useState<IPage<any>>(defaultPageValue.page);
  const [currentRoute, setCurrentRoute] = useState(ROUTES.HOME);

  return (
    <WPPageContext.Provider
      value={{
        page,
        setPage,
        isLoading,
        setIsLoading,
        subLoading,
        setSubLoading,
        currentRoute,
        setCurrentRoute,
      }}
    >
      {children}
    </WPPageContext.Provider>
  );
};

export const useWPPageContext = () => {
  return useContext(WPPageContext);
};
